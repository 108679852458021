import React from 'react';
import bg from '../assets/brick_texture.avif';

const Homepage = () => {
  return (
    <>
      <img src={bg} alt='brick_texture'/>
      <div className='absolute flex items-center inset-0 bg-[#ffe2d16f]'>
        <div className='aspect-square w-[30rem] ml-60 bg-[#68a6e06c] rounded-[50%]'></div>
      </div>
    </>
  );
};

export default Homepage;
