import React from 'react'
import Lottie from 'lottie-react';
import maintenance from '../maintenance.json';

const InProgress = () => {
  return (
    <section className='h-screen select-none p-4 bg-[#ffe2d1] flex justify-center items-center'>
      <div className='flex flex-col md:flex-row items-center gap-12'>
        <h2 className='text-6xl drop-shadow-xl sm:text-8xl text-[#68A7E0] w-full text-center sm:text-left sm:w-96 font-bold'>
          Gökdeniz Çakır
        </h2>
        <Lottie className='drop-shadow-xl' animationData={maintenance} />
      </div>
    </section>
  )
}

export default InProgress