import { Routes } from 'react-router-dom';
import { Route } from 'react-router-dom';
import InProgress from './pages/inProgress';
import Homepage from './pages/Homepage';

function App() {
  return (
    <Routes>
      <Route path='/' element={<InProgress />} />
      <Route path='/homepage' element={<Homepage />} />
    </Routes>
    
  );
}

export default App;
